import { createRouter, createWebHistory } from 'vue-router'

// var reg = new RegExp("www|sitename|test|localhost:8000");
// var parts = window.location.host.split(".");
// const indexPath = reg.test(parts[0]) ? '../views/IndexPage.vue' : '../views/IndexPage.vue'

const routes = [
  {
    path: '/',
    name: 'IndexPage',
    meta: { requiresAuth: false },
    component: () => import('../views/IndexPage.vue')
  },
  {
    path: '/video',
    name: 'VideoPage',
    meta: { requiresAuth: true },
    component: () => import('../views/VideoPage.vue')
  },
  {
    path: '/record',
    name: 'RecorderPage',
    meta: { requiresAuth: false },
    component: () => import('../views/RecorderPage.vue')
  },
  {
    path: '/words',
    name: 'WordsPage',
    meta: { requiresAuth: false },
    component: () => import('../views/games/WordsPage.vue')
  },
  {
    path: '/world',
    name: 'WorldPage',
    meta: { requiresAuth: false },
    component: () => import('../views/WorldPage.vue')
  },
  {
    path: '/cards',
    name: 'CardsPage',
    meta: { requiresAuth: false },
    component: () => import('../views/CardsPage.vue')
  },
  {
    path: '/cards2',
    name: 'Cards2Page',
    meta: { requiresAuth: false },
    component: () => import('../views/Cards2PageOld.vue')
  },
  {
    path: '/jettons',
    name: 'JettonsPage',
    meta: { requiresAuth: false },
    component: () => import('../views/JettonsPage.vue')
  },
  {
    path: '/stream',
    name: 'StreamPage',
    meta: { requiresAuth: true },
    component: () => import('../views/StreamPage.vue')
  },
  {
    path: '/stream-o',
    name: 'StreamOPage',
    meta: { requiresAuth: true },
    component: () => import('../views/StreamOPage.vue')
  },
  {
    path: '/schedule',
    name: 'SchedulePage',
    meta: { requiresAuth: false },
    component: () => import('../views/SchedulePage.vue')
  },
  {
    path: '/lessons',
    name: 'LessonsPage',
    meta: { requiresAuth: false },
    component: () => import('../views/LessonsPage.vue')
  },
  {
    path: '/games',
    name: 'GamesPage',
    meta: { requiresAuth: false },
    component: () => import('../views/GamesPage.vue')
  },
  {
    path: '/games/repka',
    name: 'GameRepka',
    meta: { requiresAuth: true },
    component: () => import('../views/games/RepkaPage.vue')
  },
  {
    path: '/games/paint',
    name: 'GamePaint',
    meta: { requiresAuth: true },
    component: () => import('../views/games/PaintPage.vue')
  },
  {
    path: '/games/puzzle',
    name: 'PuzzlePage',
    meta: { requiresAuth: false },
    component: () => import('../views/games/PuzzlePage.vue')
  },
  {
    path: '/games/letter-cards',
    name: 'GameLetterCards',
    meta: { requiresAuth: false },
    component: () => import('../views/games/LetterCards.vue')
  },
  {
    path: '/exercises/letters',
    name: 'ExerciseLetters',
    meta: { requiresAuth: false },
    component: () => import('../views/exercises/LettersPage.vue')
  },
  {
    path: '/exercises/dtt',
    name: 'DTTPage',
    meta: { requiresAuth: false },
    component: () => import('../views/exercises/DTT.vue')
  },
  {
    path: '/exercises/dtt2',
    name: 'DTTPage2',
    meta: { requiresAuth: false },
    component: () => import('../views/exercises/DTT2.vue')
  },
  {
    path: '/exercises/vp',
    name: 'VPPage',
    meta: { requiresAuth: false },
    component: () => import('../views/exercises/VP.vue')
  },
  {
    path: '/games/cards',
    name: 'GameCardsPage',
    meta: { requiresAuth: false },
    component: () => import('../views/games/GameCardsPage.vue')
  },
  {
    path: '/games/yes-no',
    name: 'GameYesNo',
    meta: { requiresAuth: false },
    component: () => import('../views/games/GameYesNo.vue')
  },
  {
    path: '/manage',
    name: 'ManagePage',
    meta: { requiresAuth: true },
    component: () => import('../views/ManagePage.vue')
  },
  {
    path: '/manage/tree',
    name: 'ManageTree',
    meta: { requiresAuth: true },
    component: () => import('../views/manage/Tree.vue')
  },
  {
    path: '/manage/cards',
    name: 'ManageCards',
    meta: { requiresAuth: true },
    component: () => import('../views/manage/Cards.vue')
  },
  {
    path: '/manage/games/paint',
    name: 'ManageGamePaint',
    meta: { requiresAuth: true },
    component: () => import('../views/manage/games/ManagePaint.vue')
  },
  {
    path: '/manage/lessons',
    name: 'ManageLessons',
    meta: { requiresAuth: true },
    component: () => import('../views/manage/ManageLessons.vue')
  },
  {
    path: '/manage/movies',
    name: 'ManageMovies',
    meta: { requiresAuth: true },
    component: () => import('../views/manage/ManageMovies.vue')
  },
  {
    path: '/manage/schedule',
    name: 'ManageSchedule',
    meta: { requiresAuth: true },
    component: () => import('../views/manage/ManageSchedule.vue')
  },
  {
    path: '/manage/exercises',
    name: 'ManageExercises',
    meta: { requiresAuth: true },
    component: () => import('../views/manage/ManageExercises.vue')
  },
  {
    path: '/manage/config',
    name: 'ManageConfig',
    meta: { requiresAuth: true },
    component: () => import('../views/manage/ManageConfig.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  routes
})

// router.beforeEach((to) => {
//   if (to.meta.requiresAuth && window.location.host.includes('demo')) {
//     return {
//       path: '/',
//     }
//   }
// })

export default router
