<template>
  <div id="_app_">
    <!-- <div v-if="$store.state.user.id" id="__app__"> -->
    <div v-if="!$store.state.user.id" class="telegram-login-button" ref="telegramLoginButton"></div>
    <div id="__app__">
      <ManageNavbarComponent v-if="$route.path.includes('/manage')"/>

      <router-view />
      
      <!-- v-if="$route.name != 'IndexPage' && !$route.path.includes('/manage')" -->
      <a
        v-if="!($route.path.includes('/manage') || $route.name === 'IndexPage')"
        @click="back"
        class="link-to-back">
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
        </svg>
      </a>

      <div
        v-if="!$route.path.includes('/manage')"
        class="position-absolute top-0 end-0 d-flex align-items-center">
        <ThemeChangeComponent />

        <VoiceRecognizeComponent v-if="$store.state.config.voice_manage"/>

        <!-- v-if="!$store.state.full_screen" -->
        <button
          @click="fullScreenChange"
          class="btn btn-link text-dark">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fullscreen" viewBox="0 0 16 16">
            <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
          </svg>
        </button>

        <button
          @mouseup="mouseup"
          @mousedown="mousedown('ManagePage')"
          @touchend="mouseup"
          @touchstart="mousedown('ManagePage')"
          class="btn btn-link text-dark">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
            <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
          </svg>
        </button>
      </div>

    </div>
    
    <!-- <div class="min-h-100-vh d-flex align-items-center justify-content-center" v-else>
      <AuthComponent />
    </div> -->

  </div>
</template>

<script>
import moment from 'moment'
// import AuthComponent from './components/AuthComponent'
import ManageNavbarComponent from './components/ManageNavbarComponent'
import ThemeChangeComponent from './components/ThemeChangeComponent'
import VoiceRecognizeComponent from './views/components/VoiceRecognizeComponent'
import NoSleep from 'nosleep.js'
// import { useRoute } from 'vue-router'
window.highlight = (query, index) => {
  const element = document.querySelectorAll(query)[index]
  const circle = document.createElement('div')
  circle.id = 'point'
  circle.style.position = 'fixed'
  circle.style.top = element.getBoundingClientRect().top + element.offsetHeight / 2 - 30 + 'px'
  circle.style.left = element.getBoundingClientRect().left + element.offsetWidth / 2 - 30 + 'px'
  circle.style.width = '60px'
  circle.style.height = '60px'
  circle.style.background = '#ff000088'
  circle.style.borderRadius = '50%'
  document.body.appendChild(circle);
  setTimeout(() => {
    circle.remove()
  }, 400)
}

export default {
  components: {
    // AuthComponent,
    ManageNavbarComponent,
    ThemeChangeComponent,
    VoiceRecognizeComponent,
  },
  data () {
    return {
      voiceRecognize: false,
      subdomain: null
    }
  },
  sockets: {
    connect: function () {
      console.log('socket connected')
    },
    play_audio: function (data) {
      this.playAudio(data.url)
    },
    notification: function ({ status, message }) {
      if (status === 'success') {
        this.$toast.success(message)
      } else {
        this.$toast.error(message)
      }
    },
    data: function ({ sets, categories, cards, set }) {
      if (sets) {
        this.$store.commit('mergeStore', { sets })
      }
      if (set && this.$store.state.set.id === set.id) {
        this.$store.commit('mergeStore', { set})
      }
      if (categories) {
        this.$store.commit('mergeStore', { categories })
      }
      if (cards) {
        this.$store.commit('mergeStore', { cards })
      }
    },
  },
  methods: {
    onTelegramAuth(user) {
      // Здесь вы можете обработать данные пользователя и выполнить вход
      console.log('onTelegramAuth', user);
    },
    addTgScript () {
      const script = document.createElement('script')
      script.src = 'https://telegram.org/js/telegram-widget.js?7'
      script.setAttribute('async', '')
      script.setAttribute('data-telegram-login', 'govori_bot')
      script.setAttribute('data-size', 'large')
      script.setAttribute('data-radius', '10')
      script.setAttribute('data-auth-url', 'https://govori.site/auth/telegram')
      script.setAttribute('data-request-access', 'write')
      this.$refs.telegramLoginButton.appendChild(script)
    },
    fullScreenChange () {
      this.$store.dispatch('checkFullScreen')
    },
    mouseup () {
      console.log('mouseup')
      clearTimeout(this.pressTimer)
      return false
    },
    mousedown (name) {
      console.log('mousedown');
      this.pressTimer = setTimeout(() => {
        this.$router.push({ name })
      }, 1500)
      return false
    },
    routeTo (name) {
      // this.$store.dispatch('checkFullScreen')
      this.$router.push({ name })
    },
    back () {
      // this.$store.dispatch('checkFullScreen')
      if (this.$route.name === 'CardsPage') {
        // window.location.href = window.location.origin.replace('cards', 'app')
        if (this.$store.state.cardsMode == 'cards') {
          return this.$store.commit('mergeStore', {cardsMode: 'categories'})  
        }
      }
      // this.$router.go(-1)
      if (this.$route.href.includes('manage/')) {
        return this.$router.push({name: 'ManagePage'})
      }
      if (this.$route.href.includes('games/') || this.$route.href.includes('exercises/') || this.$route.href.includes('jettons/')) {
        return this.$router.push({name: 'LessonsPage'})
      }
      return this.$router.push({name: 'IndexPage'})
    },
    playAudio (url) {
      const audio = new Audio(url)
      return new Promise(res => {
        audio.play()
        audio.onended = res
      })
    },
  },
  async mounted () {
    try {
      this.addTgScript()
      window.onTelegramAuth = this.onTelegramAuth;

      const url = window.location.hostname;
      this.subdomain = url.split('.')[0]

      this.noSleep = new NoSleep()
      window.noSleep = this.noSleep

      // const route = useRoute()
      if (location.hostname === 'demo.govori.site' || location.hostname === 'demo.lvh.me:8080') {
        let config = localStorage.getItem('config')
        if (config) {
          config = JSON.parse(config)
        } else {
          config = {
            set: 8,
            theme: 'light',
            timeout_autoremove_cards: 180,
            timeout_click_card: 0.5,
            jettons_width: 100,
            jettons_margin: 40
          }
          localStorage.setItem('config', JSON.stringify(config))
        }
        this.$store.commit('mergeStore', {
          demo: true,
          config
          // config: {
          //   ...this.$store.state.config,
          //   theme: route.query.theme
          // }
        })
        window.parent.postMessage(config, '*')
      }

      await this.$store.dispatch('getUser')

      const now = moment().unix()
      const reload = localStorage.getItem('reload')
      if (!reload || (now - parseInt(reload) > 86400)) {
        localStorage.setItem('reload', moment().unix())
        location.reload()
      }

    } catch (e) {
      this.$toast.error(`${e.type}: ${e.message}`)
    }
  },
  beforeUnmount () {
    delete window.onTelegramAuth;
  },
}
</script>

<style lang="scss">

$gray-900: #212529;
$offcanvas-horizontal-width: 300px;
$enable-negative-margins: true;

@import "~bootstrap/scss/bootstrap";
@import "@/assets/extra.scss";

body{
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  user-select: none;
  overflow-y: scroll;
  // cursor: none;
}
main{
  // min-height: 100vh;

}

.hover-pointer:hover{
  cursor: pointer;
}

#menu-btn{
  .dropdown-toggle::after{
    display: none;
  }
}

.btn-control{
  cursor: pointer;
  svg{
    color: white;
    width: 30px;
    height: 30px;
    @media (min-width: 340px){
      width: 40px;
      height: 40px;
    }
    @media (min-width: 768px) and (min-height: 450px) {
      width: 60px;
      height: 60px;
    }
  }
  transition: background 0.3s;
  &:hover{
    transition: background 0.3s;
  }
}

#_app_{
  min-height: 100vh;
}

html[data-bs-theme="dark"]{
  body, #_app_{
    --bd-violet-rgb: 112.520718,44.062154,249.437846;
    --bd-accent-rgb: 255,228,132;
    --bd-pink-rgb: 214,51,132;
    background-image: linear-gradient(180deg, rgba(var(--bs-body-bg-rgb), 0.01), rgba(var(--bs-body-bg-rgb), 1) 85%),radial-gradient(ellipse at top left, rgba(var(--bs-primary-rgb), 0.5), transparent 50%),radial-gradient(ellipse at top right, rgba(var(--bd-accent-rgb), 0.5), transparent 50%),radial-gradient(ellipse at center right, rgba(var(--bd-violet-rgb), 0.5), transparent 50%),radial-gradient(ellipse at center left, rgba(var(--bd-pink-rgb), 0.5), transparent 50%);
  }
}
html[data-bs-theme="light"]{
  body, #_app_{
    // --bd-accent-rgb: #ffe484;
    // --bd-violet-rgb: #712cf9;
    // --bd-pink-rgb: 214,51,132;
    background-image: linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% );
    // background-image: linear-gradient(180deg, rgba(var(--bs-body-bg-rgb), 0.01), rgba(var(--bs-body-bg-rgb), 1) 85%),radial-gradient(ellipse at top left, rgba(var(--bs-primary-rgb), 0.5), transparent 50%),radial-gradient(ellipse at top right, rgba(var(--bd-accent-rgb), 0.5), transparent 50%),radial-gradient(ellipse at center right, rgba(var(--bd-violet-rgb), 0.5), transparent 50%),radial-gradient(ellipse at center left, rgba(var(--bd-pink-rgb), 0.5), transparent 50%);
  }
}

.btn-add-card{
  background: #ccc;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  cursor: pointer;
  svg{
    width: 50px;
    height: 50px;
    color: white;
  }
  transition: background 0.3s;
  &:hover{
    background: #bbb;
    transition: background 0.3s;
  }
}

@media (min-width: 768px) and (min-height: 450px) {
  .btn-add-card{
    width: 150px;
    height: 150px;
    svg{
      width: 75px;
      height: 75px;
    }
  }
}

.link-to-back{
  cursor: pointer;
  height: 50px;
  width: 50px;
  position: fixed;
  top: 0;
  left: 0;
  border-bottom-right-radius: 10px;
  backdrop-filter: blur(10px);
  svg {
    path{
      fill: gray;
    }
  }
}

.categories-item{
  // width: 120px;
  font-size: 12px;
  svg{
    height: 25px;
    width: 25px;
  }
}

.link-to:hover, .card-item{
  cursor: pointer;
}

body{
  cursor: url('@/assets/cursor.svg'), auto;
  .link-to:hover, .card-item, .btn:hover{
    cursor: url('@/assets/cursor.svg'), auto;
  }
}
// .clickPoint{
//   position: fixed;
//   width: 50px;
//   height: 50px;
//   transition: all .1s ease;
// }
</style>
