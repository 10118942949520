<template lang="html">
  <div>

    <!-- <Vue3Lottie
      v-if="isRecording"
      class="lottie-VoiceRecognition"
      :height="100"
      :width="200"
      :animationData="VoiceRecognitionJSON"/> -->
    <div v-if="isRecording" class="loader-wrapper-record">
      <div class="loader"></div>
    </div>
    <div v-if="voiceRecognizing" class="loader-wrapper-recognize">
      <div class="loader"></div>
    </div>
    <!-- <Vue3Lottie
      v-if="voiceRecognizing"
      class="lottie-VuiJSON"
      :height="100"
      :width="100"
      :animationData="VuiJSON"/> -->

    <button
      class="rec-btn"
      :class="{
        'bg-danger text-white': isRecording,
        'text-black-50': !isRecording,
      }"
      @mouseup="stopRecording"
      @mousedown="startRecording"
      @touchend="stopRecording"
      @touchstart="startRecording">
      <!-- title="Управление голосом"
      @click="recordBtnClick"
      data-bs-toggle="tooltip"
      data-bs-placement="top" -->
      <!-- <svg v-if="$store.state.voiceRecognizing" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
      </svg> -->
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-mic" viewBox="0 0 16 16">
        <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
        <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"/>
      </svg>
      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-record-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
      </svg> -->
    </button>

    <!-- <template v-if="$store.state.audioSource"> -->
      <!-- <audio :src="$store.state.audioSource" controls></audio> -->
      <!-- <button @click="deleteRecording" class="btn btn-danger">Удалить</button> -->
      <!-- <button
        @click="saveRecording"
        class="btn btn-success">
        Сохранить
      </button> -->
    <!-- </template> -->

    <!-- <canvas
      width="512"
      height="128"
      :style="{ opacity: isRecording ? 1 : .5 }"
      v-visualize="frequencies">
    </canvas> -->

    <!-- <div class="voice-loading">
      voice
    </div> -->

  </div>
</template>

<script>
// function initVisualizer(stream) {
//   const context = new AudioContext()
//   const analyser = context.createAnalyser()
//   analyser.fftSize = 2048
//   const dataArray = new Uint8Array(analyser.frequencyBinCount)
//   const source = context.createMediaStreamSource(stream)
//   source.connect(analyser)
//   const updateData = () => {
//     analyser.getByteTimeDomainData(dataArray)
//     this.frequencies = dataArray.slice()
//     // console.log('this.frequencies', this.frequencies)
//     requestAnimationFrame(updateData)
//   }
//   updateData()
// }

import VoiceRecognitionJSON from './lottie/4031-voice-recognition.json'
import VuiJSON from './lottie/7227-vui-animation.json'

export default {
  name: 'RecorderComponent',
  data () {
    return {
      isRecording: false,
      voiceRecognizing: false,
      frequencies: null,
      VuiJSON,
      VoiceRecognitionJSON,
      // voiceLoadingLottie:
    }
  },
  methods: {
    recordBtnClick () {
      if (this.isRecording) {
        this.stopRecording()
      } else {
        this.startRecording()
      }
    },
    async startRecording() {
      try {
        this.deleteRecording()
        await this.initMediaRecorder()
        this.isRecording = true
        this.mediaRecorder.start()
      } catch (e) {
        this.$toast.error(`${e.type}: ${e.message}`)
      }
    },
    stopRecording() {
      this.isRecording = false
      this.mediaRecorder.stop()
      // this.saveRecording()
    },
    deleteRecording() {
      this.$store.commit('setData', {attr: 'audioSource', value: null})
    },
    getToken (name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    },
    // async voiceRecognize () {
    //   try {
    //     const result = await this.$store.dispatch('voiceRecognize')
    //     console.log('result', result)
    //   } catch (e) {
    //     this.$toast.error(`${e.type}: ${e.message}`)
    //   }
    // },

    async voiceRecognize () {
      try {
        this.voiceRecognizing = true
        const formData = new FormData()
        formData.append('audio', this.$store.state.blob)
        const response = await fetch(`/api/voiceRecognize`, {
          method: 'POST',
          headers: {
            'X-CSRFToken': this.getToken('csrftoken')
          },
          body: formData
        })
        if (response.status === 200) {
          const data = await response.json()
          this.text = data.text
          this.$toast.info(`Распознано: ${data.text}`)
          this.$store.commit('mergeStore', {
            ...data.merge,
            audioSource: null
          })

          if (data.merge.jettonsCount) {
            this.$store.commit('createJettons')
          }

          const audio = new Audio(data.answer_audio_url)
          audio.play()
          // await this.playAudio(audio)
          if (data.link_to) {
            this.$router.push({ name: data.link_to })
          }
          if (data.get_data) {
            this.$socket.emit('get_data', ['sets', 'set', 'categories', 'cards', 'config', 'sequences'], {})
          }
        }
      } catch (e) {
        this.$toast.error(`${e.type}: ${e.message}`)
      } finally {
        this.voiceRecognizing = false
      }
    },

    async initMediaRecorder () {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        this.mediaRecorder = new MediaRecorder(stream)
        let chunks = []
        this.mediaRecorder.addEventListener("stop", () => {
          var blob = new Blob(chunks, { type: 'audio/webm' })
          // const blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" })
          this.$store.commit('mergeStore', { blob })
          chunks = []
          this.$store.commit('mergeStore', {
            audioSource: URL.createObjectURL(blob),
          })
          this.voiceRecognize()
        })
        this.mediaRecorder.addEventListener('dataavailable', e => {
          chunks.push(e.data)
        })
      } catch (e) {
        this.$toast.error(`${e.type}: ${e.message}`)
      }
    },
  },
  directives: {
    visualize: {
      update(canvasElement, binding) {
        const context = canvasElement.getContext("2d");

        const width = canvasElement.width;
        const height = canvasElement.height;

        context.clearRect(0, 0, width, height);
        context.beginPath();

        const dataLength = binding.value.length;
        const sliceWidth = width * 1.0 / dataLength;
        let x = 0;

        for (let i = 0; i < dataLength; i++) {
          const v = binding.value[i] / 128.0;
          const y = v * height / 2;

          if (i === 0) {
            context.moveTo(x, y);
          } else {
            context.lineTo(x, y);
          }

          x += sliceWidth;
        }

        context.lineTo(width, height / 2);
        context.stroke();
      }
    }
  },
  async mounted () {
    
  }
}
</script>

<style lang="scss" scoped>
.rec-btn{
  border: none;
  background: none;
  padding: .5rem;
  border-radius: 50%;
  &:hover{
    cursor: pointer;
  }
  .bi-arrow-clockwise{
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
}

@-moz-keyframes spin {
  100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

.lottie-VoiceRecognition{
  position: fixed;
  top: 0;
  left: calc(50vw - 100px);
}
.lottie-VuiJSON{
  position: fixed;
  top: 0;
  left: calc(50vw - 50px);
}

.loader-wrapper-record {
  position: fixed;
  top: 20px;
  left: calc(50vw - 8px);

  .loader {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 32px 0 #fff, -32px 0 #fff;
    position: relative;
    animation: flash 0.5s ease-out infinite alternate;
  }

  @keyframes flash {
    0% {
      background-color: #8700ffbb;
      box-shadow: 32px 0 #8700ffbb, -32px 0 #8700ff;
    }
    50% {
      background-color: #8700ff;
      box-shadow: 32px 0 #8700ffbb, -32px 0 #8700ffbb;
    }
    100% {
      background-color: #8700ffbb;
      box-shadow: 32px 0 #8700ff, -32px 0 #8700ffbb;
    }
  }

}

.loader-wrapper-recognize{
  position: fixed;
  top: 0px;
  left: calc(50vw - 8px);
  .loader, .loader:before, .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
  }
  .loader {
    color: #fb42ffbb;
    font-size: 7px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }

  @keyframes bblFadInOut {
    0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
    40% { box-shadow: 0 2.5em 0 0 }
  }
}

</style>
