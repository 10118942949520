<template lang="html">
  <nav class="navbar fixed-top navbar-expand-lg bg-body-tertiary shadow-sm">
    <div class="container-fluid">
      <router-link :to="{name: 'IndexPage'}" class="navbar-brand">
        Сайт
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" ref="navCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link :to="{name: 'ManageCards'}" class="nav-link" active-class="active">
              Коммуникатор
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'ManagePage'}" class="nav-link" active-class="active">
              Статистика
            </router-link>
          </li>
          <li class="nav-item" v-if="$store.state.user.is_staff">
            <router-link :to="{name: 'ManageTree'}" class="nav-link" active-class="active">
              Карточки
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'ManageConfig'}" class="nav-link" active-class="active">
              Настройки
            </router-link>
          </li>
          <li v-if="$store.state.user.is_staff">
            <router-link :to="{name: 'ManageSchedule'}" class="nav-link" active-class="active">
              Расписание
            </router-link>
          </li>

          <li class="nav-item dropdown" v-if="$store.state.user.is_staff">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Разработка
            </a>
            <ul class="dropdown-menu">
              <li>
                <router-link :to="{name: 'SchedulePage'}" class="dropdown-item" active-class="active">
                  Расписание интерфейс
                </router-link>
              </li>
              <li v-if="$store.state.user.is_staff">
                <router-link :to="{ name: 'VPPage' }" class="dropdown-item">
                  Визуальное восприятие
                </router-link>
              </li>
              <li v-if="$store.state.user.is_staff">
                <router-link :to="{ name: 'DTTPage' }" class="dropdown-item">
                  Изучение слов
                </router-link>
              </li>
              <li v-if="$store.state.user.is_staff">
                <router-link :to="{ name: 'RecorderPage' }" class="dropdown-item">
                  Запись
                </router-link>
              </li>
              <li v-if="$store.state.user.is_staff">
                <router-link :to="{name: 'ManageMovies'}" class="dropdown-item" active-class="active">
                  Видео
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'ManageGamePaint'}" class="dropdown-item" active-class="active">
                  Буквы
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'ManageExercises'}" class="dropdown-item" active-class="active">
                  Упражнения
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'Cards2Page'}" class="dropdown-item" active-class="active">
                  Карточки 2
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'WorldPage'}" class="dropdown-item" active-class="active">
                  Окружающий мир
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item ps-3 pt-1">
            <button @click="$store.dispatch('logout')" class="btn btn-sm btn-outline-secondary">
              Выход
            </button>
          </li>

        </ul>
        <ul class="navbar-nav">
          <li class="nav-item">
            <ThemeChangeComponent />
          </li>
          <li class="nav-item">
            <VoiceRecognizeComponent v-if="$store.state.config.voice_manage"/>
          </li>
        </ul>
        <!-- <form class="d-flex" role="search">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form> -->
      </div>
    </div>
  </nav>
</template>

<script>
import { Collapse } from 'bootstrap'
import ThemeChangeComponent from './ThemeChangeComponent'
import VoiceRecognizeComponent from '../views/components/VoiceRecognizeComponent'
export default {
  components: {
    ThemeChangeComponent,
    VoiceRecognizeComponent
  },
  data () {
    return {
      navCollapse: null
    }
  },
  watch: {
    '$route' () {
      this.hideCollapse()
      // console.log('watch route', to, from)
      // handler: function(search) {
      //   console.log(search)
      // },
      // deep: true,
      // immediate: true
    }
  },
  methods: {
    hideCollapse () {
      if (this.$refs.navCollapse.classList.contains('show')) {
        this.navCollapse.hide()
      }
    },
  },
  mounted () {
    this.navCollapse = new Collapse(this.$refs.navCollapse, {
      toggle: false
    })
  },
}
</script>

<style lang="css" scoped>
</style>
