/* eslint-disable no-console */

import { register } from 'register-service-worker'

// if (process.env.NODE_ENV === 'production') {
register(`${process.env.BASE_URL}service-worker.js`, {
  ready () {
    console.log(
      'App is being served from cache by a service worker.\n' +
      'For more details, visit https://goo.gl/AFskqB'
    )
  },
  registered () {
    console.log('Service worker has been registered.')
  },
  cached () {
    console.log('Content has been cached for offline use.')
  },
  updatefound () {
    console.log('New content is downloading.')
  },
  updated () {
    // updated (registration) {
    console.log('New content is available; please refresh.')
    // let worker = registration.waiting
    // if (worker) {
    //   worker.postMessage({ action: 'skipWaiting' })
    // }
  },
  offline () {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error (error) {
    console.error('Error during service worker registration:', error)
  }
})
// }

// self.addEventListener('message', (event) => {
//   if (event.data && event.data.action === 'skipWaiting') {
//     self.skipWaiting();
//   }
// });

// self.addEventListener('install', function(event) {
//   console.log('install', event)
//   self.skipWaiting();
// });

// self.addEventListener('activate', event => {
//   event.waitUntil(self.clients.claim());
// });