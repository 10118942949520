import axios from 'axios'

function getToken(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const api = {

  async getMe () {
    return axios.get(`/api/me?host=${window.location.host}`)
  },

  async signup (context) {
    return axios.post('/api/signup', context.state.user, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async login (context) {
    return axios.post('/api/login', {
      username: context.state.user.username,
      password: context.state.user.password,
    }, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async logout () {
    return axios.post('/api/logout', {}, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async saveImage ({ state }) {
    return axios.post(`/api/cards/${state.card.id}/image`, {
      // image_url: state.card.image,
      image_coordinates: state.image_coordinates,
    }, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async getImageFromUrl ({ state }) {
    return axios.post(`/api/cards/${state.card.id}/url`, {
      url: state.image_url,
    }, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async getPhotos ({ state }) {
    return axios.get(`/api/photos?text=${state.photos_query}&page=${state.photos_page}`)
  },

  async uploadAudio (context) {
    const formData = new FormData()
    // const blob = new Blob(context.state.audioSource, { type: "audio/webm" })
    formData.append('audio', context.state.blob)
    return axios.post(`/api/uploadAudio/${context.state.card._id ? context.state.card._id : 0}${context.state.no_name ? `?name=${context.state.no_name}`: ''}`, formData, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async voiceRecognize (context) {
    const formData = new FormData()
    formData.append('audio', context.state.blob)
    return axios.post(`/api/voiceRecognize`, formData, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async uploadFile (context) {
    const formData = new FormData()
    // const blob = new Blob(context.state.audioSource, { type: "audio/webm" })
    formData.append('file', context.state.file)
    return axios.post(`/api/upload/${context.state.card.id}`, formData, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async deleteSet (state) {
    return axios.delete(`/api/sets/${state.set.id}`, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async deleteCategory (state) {
    return axios.delete(`/api/categories/${state.category.id}`, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async saveCard (context) {
    return axios.post(`/api/cards`, context.state.card, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async text2speech ({ state }) {
    return axios.get(`/api/cards/${state.card.id}/speech`, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async getAudio ({ state }, record_seq) {
    let url = `/api/audio?card_ids=${state.list.map(c => c.id).join(',')}`
    if (state.set.id) {
      url += `&set_id=${state.set.id}`
    }
    if (state.record_seqences) {
      url += `&save=${record_seq}`
    }
    return axios.get(url, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async deleteCard (state) {
    return axios.delete(`/api/cards/${state.card.id}`, {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async saveCategoriesOrder (context) {
    return axios.post(`/api/categories/sort`, context.state.categories.map(c => c.id), {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

  async saveCardsOrder ({ state }) {
    return axios.post(`/api/cards/sort`, state.category_cards.map(c => c.id), {
      headers: {
        'X-CSRFToken': getToken('csrftoken')
      }
    })
  },

}
